import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logos from './logosB64.js'
import { s3url } from '@/assets/js/helpers.js'
import i18n from '@/i18n.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function generatePdf (
  formType,
  user,
  installation,
  device,
  schema,
  params,
  notes,
  signatureImage,
  inspection = null,
  openPDF = true,
  lang
) {
  const t = i18n.global.t
  const installationDate = new Date(installation.installationDate).toLocaleDateString('pl-PL')

  let deviceData
  if (device.parameters) {
    const schemaVersion = device.schemaVersion ? device.schemaVersion : device.deviceTemplate.activeVersion
    deviceData = schemaVersion.parametersSchema.map(field => {
      return {text: [{text: `${field.labels?.[lang] ? field.labels[lang] : field.label}: `, bold: true}, device.parameters[field.name]], margin: [0, 0, 0, 2]}
    }).filter(value => { return value.text[1] })
    deviceData.unshift({text: [{text: `${t('pdf.technical.deviceType', lang)}: `, bold: true}, device.deviceTemplate.nameTranslations?.[lang] ? device.deviceTemplate.nameTranslations[lang] : device.deviceTemplate.name], margin: [0, 0, 0, 2]})
  }
  deviceData.push({text: [{text: `${t('installation.installationDate', lang)}: `, bold: true}, installationDate], margin: [0, 0, 0, 2]})

  let inspectionData
  let scheduledDate
  let executionDate
  if (inspection && inspection.requiredForWarranty) {

    scheduledDate = new Date(inspection.scheduledDate).toLocaleDateString('pl-PL')
    executionDate = new Date()
    inspectionData = [
      {text: [{text: t('pdf.technical.warrantyInspection', lang), bold: true}], margin: [0, 0, 0, 2]},
      {text: [{text: `${t('pdf.technical.dueDate', lang)}: `, bold: true}, scheduledDate], margin: [0, 0, 0, 2]},
      {text: [{text: `${t('pdf.technical.executedAt', lang)}: `, bold: true}, executionDate.toLocaleDateString('pl-PL')], margin: [0, 0, 0, 2]}
    ]
  }

  const personalData = {
    columns: [
      [
        {
          text: t('pdf.technical.investorData', lang),
          alignment: 'left',
          margin: [0, 30, 0, 5],
          style: 'subheader'
        },
        {text: [{text: `${t('installation.customerName', lang)}: `, bold: true}, installation.customerName], margin: [0, 0, 0, 2]},
        {text: [{text: `${t('installation.address', lang)}: `, bold: true}, installation.customerAddress], margin: [0, 0, 0, 2]},
        {text: [{text: `${t('installation.city', lang)}: `, bold: true}, installation.customerCity], margin: [0, 0, 0, 2]},
        {text: [{text: `${t('installation.zipCode', lang)}: `, bold: true}, installation.customerZipcode], margin: [0, 0, 0, 2]}
      ],
      [
        {
          text: t('pdf.technical.installerData', lang),
          alignment: 'left',
          margin: [0, 30, 0, 5],
          style: 'subheader'
        },
        {text: [{text: `${t('installation.customerName', lang)}: `, bold: true}, user.name], margin: [0, 0, 0, 2]},
        {text: [{text: `${t('installation.company', lang)}: `, bold: true}, user.company], margin: [0, 0, 0, 2]},
        {text: [{text: `${t('users.tin', lang)}: `, bold: true}, user.tin], margin: [0, 0, 0, 2]}
      ],
    ]
  }

  const legacyTranslation = {
    'ext unit': 'Zdjęcie jednostki zewnętrznej',
    'termic insulation': 'Zdjęcie izolacji termicznej',
    'condensate drain': 'Zdjęcie odprowadzenia kondensatu',
    'int unit': 'Zdjęcie jednostki wewnętrznej',
    'hydraulic connections': 'Zdjęcie hydrauliki',
    'electric board': 'Zdjęcie tablicy elektrycznej klienta',
    'pressure reductor': 'Zdjęcie nastawy reduktora ciśnienia'
  }

  let schemaPart = []
  schema.forEach((step, stepIndex) => {
    if (step.schema.every(field => {return field.type == 'signature'})) return;
    schemaPart.push({
      text: step.names?.[lang] ? step.names[lang] : step.name,
      alignment: 'left',
      margin: [0, 30, 0, 5],
      style: 'subheader'
    })
    const fields = []
    step.schema.forEach((field) => {
      const stepParams = params[stepIndex]
      const name = field.type == 'image' ? field.imageName : field.name
      if (Object.prototype.hasOwnProperty.call(stepParams, name)) {
        if (field.type == "yesno") fields.push({text: [`${field.labels?.[lang] ? field.labels[lang] : field.label}: `, {text: stepParams[field.name] ? t('yes') : t('no'), bold: true}], margin: [0, 0, 0, 2]})
        else if (field.type == "image" && stepParams[field.imageName]) {
          const label = device.deviceTemplate.isSpecial && legacyTranslation[field.imageLabel] ? legacyTranslation[field.imageLabel] : (field.imageLabels?.[lang] ? field.imageLabels[lang] : field.imageLabel)
          const link =  device.id ? `${s3url}${device.id}${inspection ? `-${executionDate.getTime()}` : ''}-${field.imageName}.jpg` : null
          fields.push({text: [`${t('pdf.technical.image', lang)}: `, {text: label, bold: true, link: link}], margin: [0, 0, 0, 2]})
        }
        else if (field.type !== "signature") fields.push({text: [`${field.labels?.[lang] ? field.labels[lang] : field.label}: `, {text: stepParams[field.name], bold: true}], margin: [0, 0, 0, 2]})
      }
    });
    schemaPart.push(fields)
  });


  const pdfNotesPart = {
    columns: [
      [
        {text: t('template.customerNotes', lang), bold: true, margin: [0, 0, 0, 2]},
        {text: notes.client ? notes.client : t('pdf.technical.noneNotes', lang)}
      ],
      [
        {text: t('template.installerNotes', lang), bold: true, margin: [0, 0, 0, 2]},
        {text: notes.installer ? notes.installer : t('pdf.technical.noneNotes', lang)}
      ]
    ],
    margin: [0, 30, 0, 5]
  }

  const signaturePart = signatureImage ? [
    {
      image: signatureImage,
      width: 200,
      alignment: 'right',
      margin: [0, 30, 0, 0]
    },
    {
      columns: [
        {},
        [
          {
            text: t('pdf.technical.investorSignature', lang),
            style: 'small',
            alignment: 'center'
          },
          {
            text: formType=='installation' ? installationDate : executionDate.toLocaleDateString('pl-PL'),
            style: 'small',
            alignment: 'center'
          },
        ]
      ]
    }
  ] : []

  const docDefinition = {
    content: [
      {
        image: logos[process.env.VUE_APP_ORG_ID],
        width: 200,
        alignment: 'center'
      },
      {
        text: formType=='installation' ? t('installation.installationCard', lang) : t('inspection.inspectionCard', lang),
        alignment: 'center',
        margin: [0, 20],
        style: 'header'
      },
      {
        columns: [
          [
            {
              text: t('pdf.technical.deviceData', lang),
              alignment: 'left',
              margin: [0, 0, 0, 5],
              style: 'subheader'
            },
            deviceData,
          ],
          [
            {
              text: '',
              alignment: 'left',
              margin: [0, 15, 0, 5],
              style: 'subheader'
            },
            inspectionData
          ]
        ]
      },
      personalData,
      schemaPart,
      pdfNotesPart,
      signaturePart
    ],
    defaultStyle: {
      fontSize: 10,
    },
    styles: {
       header: {
         fontSize: 16,
         bold: true
       },
       subheader: {
         fontSize: 14,
         bold: true
       },
       quote: {
         italics: true
       },
       small: {
         fontSize: 8
       }
    }
  }

  const pdfDocGenerator = pdfMake.createPdf(docDefinition)
  if (openPDF) pdfDocGenerator.open();
  return new Promise( resolve => {
    pdfDocGenerator.getBase64((file) => {
      resolve({file, executionDate})
    });
  });

}

export default generatePdf;
